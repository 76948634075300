<template>
    <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="500px">
        <!-- dialog ยกเลิก consent -->
      <v-card v-if="key_show === 'cancel'">
        <v-card-title>{{$t('consent.confirmcancel')}}</v-card-title>
        <v-divider></v-divider>
        <br/>
        <v-card-text >
          {{$t('consent.confirmcanceltext1')}} {{dataconsent.consent_name}} {{$t('consent.confirmcanceltext2')}}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="red" outlined  @click="closecancel()">{{$t('admin.close')}}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme"  @click="submitcancel()" >{{$t('admin.submit')}}</v-btn>
        </v-card-actions>
      </v-card>
      <!-- dialog detail consent -->
      <v-card v-else-if="key_show === 'detail'">
        <v-card-title>{{$t('consent.dialogdetail')}}</v-card-title>
        <v-divider></v-divider>
        <br/>
        <v-card-text>
          {{dataconsent.consent_detail}}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="red" dark @click="closedetail()">{{$t('admin.close')}}</v-btn>
          <!-- <v-btn :color="color.theme" :dark="color.darkTheme"  @click="submitdetail()" >{{$t('admin.submit')}}</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
 
  //    mixins: [validationMixin],
  //  validations: {
  //     filename: { required },
  //   },
    props: ['show','key_show','dataconsent'],
    data: function() {
      return {
        createprogress:false,
      }

    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                   console.log(this.dataconsent);
                }
                return this.show
            },
            set (value) {
                if (!value) {
                  this.$emit('closechangename')
                }
            }
        },},
      
       methods: {
         submitcancel(){
           this.$emit('confirmcancel',this.dataconsent);
           this.$emit('close')
         },
         closecancel(){
          this.$emit('close')
         },
         closedetail(){
          this.$emit('close')
         }
           
       }
      
}

</script>
<style>

</style>